.labelled-checkbox {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 10px 0;
  pointer-events: none;
}

.labelled-checkbox .checkbox,
.labelled-checkbox label {
  pointer-events: all;
}

.labelled-checkbox .checkbox {
  flex-shrink: 0;
  margin-right: 15px;
}

.labelled-checkbox label {
  user-select: none;
  padding-right: 20px;
}

.labelled-checkbox:hover .checkbox.checked::before {
  transform: translateX(calc(-50% + 1px)) rotateZ(-50deg) scale(1.05);
}

.labelled-checkbox:hover .checkbox.checked::after {
  transform: translateX(calc(-50% - 1px)) rotateZ(30deg) scale(1.05);
}

@media not all and (min-width: 420px) {
  .labelled-checkbox {
    padding: 0.4rem 0;
  }
}