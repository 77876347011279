.button-centering {
  text-align: center;
}

.button {
  display: inline-block;
  max-width: calc(100vw - 40px);
  width: 240px;
  padding: 10px 0;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  user-select: none;
}

.button.disabled {
  opacity: 0.5;
  pointer-events: none;
}


/** DARK THEME */

.theme-dark .button {
  border-radius: 25px;
  background-color: #dc386f;  /* middle of the gradient */
  background-image: linear-gradient(to right, #f14062, #c82f7b);
  color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.02), 0 1px 2px rgba(0,0,0,0.14);
  transition: box-shadow 150ms;
}

.theme-dark .button:hover {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  filter: brightness(105%);
}

.theme-dark .button:active {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  filter: brightness(110%);
}
