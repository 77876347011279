#page-machine-serial {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  min-height: calc(100vh - 80px); /* remove .content padding */
}

#page-machine-serial.sending {
  opacity: 0.5;
  pointer-events: none;
}

#page-machine-serial p {
  text-align: center;
}

#page-machine-serial .error-message {
  color: #ee4066;
  font-weight: 500;
  margin: 30px 0 10px;
}

/** INPUT */

#page-machine-serial .input-centering {
  text-align: center;
}

#page-machine-serial input {
  max-width: calc(100vw - 40px);
  width: 240px;
  padding: 6px 5px 6px 15px;
  border-radius: 3px;
  border: 1px solid white;
  -webkit-appearance: none !important;
}

/** MACHINE PREVIEW */

#page-machine-serial .machine-preview {
  margin: 20px 0;
}

#page-machine-serial .machine-name {
  margin: 0 0 15px;
  font-weight: 500;
  text-transform: uppercase;
}

#page-machine-serial .machine-image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: white;
}

#page-machine-serial .machine-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/** BUTTON */

#page-machine-serial .button-centering {
  margin-top: 20px;
  margin-bottom: 40px;
}

#page-machine-serial .submit-button {
  margin-top: 20px;
}

/** SKIP REGISTRATION */

#page-machine-serial .spacing {
  flex: 1;
  margin-bottom: 20px;
}

#page-machine-serial .skip-registration-container {
  display: flex;
  justify-content: center;
  user-select: none;
}

#page-machine-serial .skip-registration-container span {
  position: absolute;
  bottom: 20px;
  cursor: pointer;
}
