#page-thank-you p {
  text-align: center;
  font-weight: 500;
}


/** SONG CREDITS */

#page-thank-you .song-credits {
  margin: 30px 0;
}

#page-thank-you .song-credits p {
  margin: 0;
  font-weight: 400;
}

#page-thank-you .song-credits p:not(:last-child) {
  margin-bottom: 5px;
}

#page-thank-you .song-credits strong {
  font-size: 1.4rem;
  font-weight: 300;
  color: #ee4066;
}


/** CONTINUE BUTTON */

#page-thank-you .button-centering {
  margin-top: 100px;
}
