#page-communications.sending {
  opacity: 0.7;
  pointer-events: none;
}

#page-communications .error-message {
  text-align: center;
  color: #ee4066;
  font-weight: 500;
  margin-bottom: 20px;
}

#page-communications .centered-link {
  margin: 30px 0 20px;
}

#page-communications .centered-link p {
  margin: 0;
  line-height: 1;
  text-align: center;
}

#page-communications .align-labelled-checkbox-text {
  padding-left: 40px;
}

@media not all and (min-width: 420px) {
  #page-communications {
    font-size: 0.9rem;
  }

  #page-communications .centered-link {
    margin: 1rem 0 1.5rem;
  }
}
