.checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  background-color: white;
  border: 1px solid #555;
}

.checkbox::before,
.checkbox::after {
  position: absolute;
  bottom: 1px;
  left: 50%;
  width: 5px;
  background-color: #ee4066;
  border-radius: 10px 10px 2px 2px;
  transform-origin: bottom center;
  filter: brightness(100%);
  transition: transform 150ms;
}

.checkbox::before {
  height: 14px;
  transform: translateX(calc(-50% + 1px)) rotateZ(-50deg) scale(1);
}

.checkbox::after {
  height: 34px;
  transform: translateX(calc(-50% - 1px)) rotateZ(30deg) scale(1);
}

.checkbox.checked::before,
.checkbox.checked::after {
  content: "";
}

.checkbox.checked:hover::before,
.checkbox.checked:hover::after {
  filter: brightness(150%);
}
