@import "../../style/index.css";


html, body {
  font-family: proximanova, Roboto, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  background-color: #252525;
}


/** THEMES */


#app {
  min-height: 100vh;
}

#app.theme-dark {
  background-color: #252525;
  color: white;
}

#app.theme-light {
  background-color: white;
  color: rgba(black, 0.9);
}


/** GLOBAL LAYOUT */


/**
 * We handle larger screens by centering the content of the page, and setting
 * a "maximum width" for the interface of 480px.
 */
#app > .content {
  position: relative;
  max-width: 600px;
  width: calc(100vw - 40px);
  margin: 0 auto;
  padding: 40px 0;
}

@media not all and (min-width: 420px) {
  #app > .content {
    padding: 1.75rem 0;
  }
}


/** LINKS */


a {
  color: #ee4066;
  text-decoration: none;
  font-weight: 400;
  transition: color 200ms;
}

a:hover {
  color: white;
}
