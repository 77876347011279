/*************************
 * FONT DEFINITION
 */


 @font-face {
  font-family: "StingrayIcons";
  font-display: block;
  font-weight: normal;
  src: url("./stingrayiconsset-webfont.eot?#iefix") format("embedded-opentype"),
       url("./stingrayiconsset-webfont.woff2") format("woff2"),
       url("./stingrayiconsset-webfont.woff") format("woff"),
       url("./stingrayiconsset-webfont.ttf") format("truetype"),
       url("./stingrayiconsset-webfont.svg#StingrayIcons") format("svg");
}



/*************************
 * ICON STYLING
 */


.si {
  display: inline-block;
  font: normal normal normal 1em/1em StingrayIcons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/*************************
 * ICON CLASSES
 */

/** Arrows */
.si-chevron-left:before { content: "3"; }
.si-chevron-right:before { content: "4"; }
.si-chevron-up:before { content: "5"; }
.si-chevron-down:before { content: "6"; }
.si-caret-up:before {content: '"'; }
.si-caret-down:before {content: "#"; }
.si-caret-left:before {content: "8"; }
.si-caret-right:before {content: "7"; }

/** Social media */
.si-facebook:before { content: "("; }
.si-facebook-square:before { content: ")"; }
.si-google-plus:before { content: "."; }
.si-pinterest:before { content: "/"; }
.si-twitter:before { content: "'"; }
.si-tumbler:before { content: "0"; }
.si-youtube:before { content: "*"; }
.si-youtube-simple:before { content: "+"; }
.si-instagram:before { content: "&"; }

/** Controls */
.si-cog:before { content: "-"; }
.si-heart:before {content: ":"; }
.si-play:before { content: "2"; }
.si-search:before { content: "Z"; }
.si-close:before { content: "X"; }
.si-block:before { content: "<"; }
.si-like:before { content: ":"; }
.si-filter:before { content: "Y"; }
.si-plus:before { content: ">"; }
.si-minus:before { content: "?"; }
.si-stop:before { content: "S"; }

/** Flairs */
.si-listen:before { content: "L"; }
.si-gallery:before { content: "G"; }
.si-podcast:before { content: "P"; }

/** Brand */
.si-stingray:before { content: "%"; }
.si-stingray-full:before { content: "$"; }

/** Miscellaneous */
.si-calendar:before { content: "9"; }
.si-custom-channel:before {content: ";"; }
.si-disk:before:before { content: ","; }
.si-link-external:before { content: "1"; }
.si-email:before { content: "E"; }
